.contact {
  flex-direction: column;
}

.contact__list {
  max-width: 450px;
  width: 25%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
