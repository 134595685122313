/* ScrollIndicator.css */

.scroll-indicator {
  position: fixed;
  max-height: 10%;
  font-size: 3em;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 5px;
  transition: opacity 0.3s ease-in-out;
}

.scroll-indicator.light {
  background-color: rgba(255, 255, 255, 0.8); /* Light background color */
}

/* .scroll-indicator.dark {
  background-color: rgba(0, 0, 0, 0.8);
} */

.carat {
  color: black; /* Default color */
}

.scroll-indicator.dark {
  color: var(--clr-fg);
  background-color: transparent;
}
