.about {
  flex-direction: column;
}

.about img {
  border-radius: 50%;
  max-width: 11em;
  margin-bottom: 3em;
}

.about__intro {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__name {
  color: var(--clr-primary);
}

.about__role {
  margin-top: 1.2em;
}

.about__desc {
  font-size: 1rem;
  max-width: 600px;
}

.about__desc a {
  text-decoration: underline;
  color: var(--clr-primary);
}

.about__contact {
  gap: 10px;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    margin-top: 2em;
  }

  .app .about__intro {
    display: flex;
    flex-direction: row-reverse;
  }

  #profilePic {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }
}
